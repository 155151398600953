.recipe{
    border-radius: 10px;
    box-shadow: 0px 5px 20px rgb(71, 71, 71);
    margin: 20px;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    background: white;
    align-items: center;
    min-width: 50%;
}

.image{
    border-radius: 50%;
    width: 100px;
    height: 100px;

}
