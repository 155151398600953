.App {
  min-height: 100vh;
  background-image: linear-gradient(to top, #ff9a9e 0%, #fecfef 99%, #fecfef 100%);
}

.search-form{
  min-height: 10vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.search-bar{
  width: 50%;
  border: none;
  padding: 10px;
}

.search-button{
  background: lightcoral;
  border: none;
  padding: 10px;
  color: white;
}

.recipes{
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
}

nav {
  display: flex;
  justify-content: space-around;
  align-items: center;
  min-height: 10vh;
  background: rgb(73, 79, 82);
  color: white;
}

.nav-links {
  width: 40%;
  display: flex;
}

