.title {
    border-radius: 50%;
    width: 50px;
    height: 50px;
    color: red;
    font-size: 40px;
    font-weight: 800;
    letter-spacing: 5px;
    text-align: center;
    padding-right: 8%;
}

.page {
    border-radius: 10px;
    box-shadow: 0px 5px 20px rgb(71, 71, 71);
    margin: 20px;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    background: navy;
    align-items: center;
    min-width: 50%;
}

.recipe{
    border-radius: 10px;
    box-shadow: 0px 5px 20px rgb(71, 71, 71);
    margin: 20px;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    background: white;
    align-items: center;
    min-width: 50%;
}

.image{
    border-radius: 50%;
    width: 100px;
    height: 100px;

}
